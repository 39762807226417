/* import suneditor css
/* prefix ~ at the start of the path tells Webpack's css-loader to resolve the
 import "like a module", starting from the node_modules directory. */
@import "~suneditor/dist/css/suneditor.min.css";

.margin-right10 {
    margin-right: 10px;
}

.margin-top10 {
    margin-top: 10px;
}

.font-bold {
    font-weight: bold;
}

.text-uppercase {
    text-transform: uppercase;
}

.pull-right {
    float: right;
    margin-left: auto !important;
}

.ReactGridGallery_tile-viewport {
    margin: 0 !important;
}

.ReactGridGallery_tile-icon-bar ~ .ReactGridGallery_tile-viewport > img {
    width: 100% !important;
    height: 100% !important;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image,
.product-image-container {
    width: 200px;
    height: 200px;
    position: relative;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image img,
.img-fit-container {
    position: absolute;
    top: 0;
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;
}

.clickable-container * {
    cursor: pointer;
}

.white-color {
    color: ghostwhite;
}

.green-color {
    color: lawngreen;
}

.ReactGridGallery_tile-viewport {
    min-height: 200px;
    min-width: 200px;
}

.button-opacity {
    opacity: 0.8;
}

.select-flat-label,
div.select-flat-label,
label.select-flat-label,
.text-field-flat-label,
div.text-field-flat-label,
label.text-field-flat-label,
.text-field-flat-label-multiline,
div.text-field-flat-label-multiline,
label.text-field-flat-label-multiline,
.text-field-flat-label-number,
div.text-field-flat-label-number,
label.text-field-flat-label-number {
    padding: 12px;
    box-sizing: border-box;
}

label.select-flat-label span,
label.text-field-flat-label span,
label.text-field-flat-label-multiline span {
    color: rgba(0, 0, 0, 0.54) !important;
    width: 100%;
}

label.text-field-flat-label-number span {
    color: rgba(0, 0, 0, 0.54) !important;
    max-width: 100%;
}

label.select-flat-label > div {
    padding-left: 10px;
}

label.text-field-flat-label > div,
label.text-field-flat-label-number > div {
    padding-left: 10px;
    min-width: 200px;
}

@media screen and (min-width: 700px) {
    label.text-field-flat-label-multiline > div {
        padding-left: 10px;
        min-width: 50vw;
    }
}

@media screen and (max-width: 699px) {
    label.text-field-flat-label-multiline > div {
        padding-left: 10px;
        min-width: 340px;
    }
}

@media screen and (max-width: 400px) {
    label.select-flat-label span,
    label.text-field-flat-label span,
    label.text-field-flat-label-number span,
    label.text-field-flat-label-multiline span {
        width: auto;
    }
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {
    display: inline-flex;
}

.number-input,
.number-input * {
    box-sizing: border-box;
}

.number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: "";
    width: 1rem;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.54);
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

span.disabledPrimarySwitch > span {
    color: #2138a3 !important;
}

span.disabledPrimarySwitch > span:last-child {
    background-color: #2138a3 !important;
}

.primary-bg-color {
    background: linear-gradient(60deg, #ab47bc, #8e24aa) !important;
}

/* .MuiGrid-root > .MuiGrid-item {
    max-width: 100% !important;
} */
